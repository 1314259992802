import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});


function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);

  const imageUrls = [
    'https://i.ibb.co/GHRBxb0/cropped-photo-2024-02-17-17-35-47.jpg',
    'https://i.ibb.co/MfFxNjf/cropped-photo-2024-02-17-17-35-58.jpg',
    'https://i.ibb.co/K7xbWYX/cropped-photo-2024-02-17-17-36-00.jpg',
    'https://i.ibb.co/J3LCfQL/cropped-photo-2024-02-17-17-36-01.jpg',
    'https://i.ibb.co/MV4hQQr/cropped-photo-2024-02-17-17-36-03.jpg',
    'https://i.ibb.co/G30m3XQ/cropped-photo-2024-02-17-17-36-04.jpg',
    'https://i.ibb.co/FznsHnK/cropped-photo-2024-02-17-17-36-05.jpg',
    'https://i.ibb.co/YTsD2jn/cropped-photo-2024-02-17-17-36-07.jpg'
  ];

  return (

    <div className="container">
    <div className="main__name">Camila</div>
    <div className='text--centered'>
              <span>
              📍 {city}, {region}   {country && <img 
                  src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                  alt={`${country} Flag`}
                  style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
                />} 
              </span>
            </div>

     <div className='text--centered'>
     🟢 Online
     </div>

      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'3'}
        coverflowEffect={{
        rotate: 15,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
       }}
      autoplay={{ // Corrected from autoPlay to autoplay
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
      className="swiper-container"
    >
      {imageUrls.map(url => (
        <SwiperSlide key={url} className="swiper-slide">
          <img src={url} alt="slide_image"/>
        </SwiperSlide>
      ))}
        <div className="slider-controler">
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>

      <div className='main__message--bubble'>
      
        <section>
            <div className='flexRow'>
              <span>
              Just moved to {city} looking for someone to make content with me😊
              </span>
            </div>

            <div className="main__message--bubble__icon"></div>
        </section>
        
        
      </div>

      <a href="https://onlyfans.com/camilleexx/c25" id="customButton">
      🔞TEXT ME PRIVATELY🔞
      </a>
      <Analytics/>
    </div>

  );
}

export default App;
